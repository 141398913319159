import React, { useState } from "react"
import Axios from "axios"
import { Link, navigate } from "gatsby"
import { Form, Button, Col } from "react-bootstrap"
import Alert from "../alert"

const LoginSection = ({ endpoint, redirectURL, onSucess, validate, location }) => {
  const [state, setState] = useState({
    submitting: false,
    status: null,
  })

  const handleServerResponse = (ok, msg, form) => {
    setState({
      submitting: false,
      status: { ok, msg },
    })
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    const formElement = e.target
    const formData = new FormData(formElement)

    let data = {}
    for (let [k, v] of formData.entries()) {
      data[k] = v
    }

    if (validate && validate instanceof Function) {
      data = validate(data)
      const { status, message, data: dataMassaged } = data

      if (status === false) {
        handleServerResponse(false, message, formElement)
      } else {
        data = dataMassaged
      }
    }

    setState({ submitting: true })
    Axios({
      method: "post",
      url: endpoint,
      data,
    })
      .then(({ data }) => {
        if (onSucess && onSucess instanceof Function) {
          onSucess(data)
        }

        if (redirectURL) {
          if (typeof window !== "undefined") {
            navigate(redirectURL, {
              redirectedFrom: "/login",
            })
          }
        }

        handleServerResponse(true, "Authenticated!", formElement)
      })
      .catch(({ response }) => {
        handleServerResponse(
          false,
          ((response || {}).data || {}).message || "Not valid credentials",
          formElement,
        )
      })
  }

  return (
    <div className="col-lg-10 col-xl-8 text-center text-blue">
      <h2 className="mb-2 mb-md-4"><strong>Login</strong></h2>
      {((location || {}).state || {}).msg && location.state.msg.length
        ? location.state.msg.map(m => (
          <Alert type={m.type} message={m.body} title={m.title}/>
        ))
        : null}
      <p className="mb-2 mb-md-4">Please login using your existing account or create a new account.</p>
      <div className="bg-form round p-3 p-lg-5 box-shadow1 mt-5">
        <div className="row">

          <Col className="col-md-6 col-12 text-left">
            {((state || {}).status || {}).ok === false ? (
              <Alert
                type="danger"
                title="Login Error"
                message={((state || {}).status || {}).msg}
              />
            ) : null}
            <h3 className="mb-3">Returning user</h3>
            <Form onSubmit={handleOnSubmit}>
              <fieldset disabled={(state || {}).submitting === true}>
                <Form.Group>
                  <label htmlFor="exampleInputEmail1">*Email address</label>
                  <Form.Control
                    name="email"
                    type="email"
                    label="email"
                    placeholder="Email"
                    required
                    className="form-control round"
                  />
                </Form.Group>
                <Form.Group>
                  <label htmlFor="exampleInputPassword1">*Password</label>
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="Password"
                    required
                    className="form-control round"
                  />
                </Form.Group>
                <div className="text-center">
                  <Button
                    type="submit"
                    varient="primary"
                    className="btn btn-primary btn-sm"
                  >
                    Sign In
                  </Button>
                </div>
              </fieldset>
            </Form>
          </Col>
          <Col className="col-md-6 col-lg-4 offset-lg-2 text-left mt-4 mt-md-0">
            <h3 className="mb-3">Don't have an account?</h3>
            <Button
              className="btn btn-primary btn-sm"
              onClick={() => {
                if (typeof window !== "undefined") {
                  navigate("/register", {
                    redirectedFrom: "/login",
                  })
                }
              }}
            >
              New User
            </Button>
            <h3 className="mb-3 mt-0 mt-lg-5"> </h3>
            <Link
              to="/forgot-password"
              className="text-blue2 float-md-left mt-lg-4 mt-sm-5 mt-0 text-center text-lg-left">Forgot your
              password?</Link>

            {/*<div className="mt-3">*/}
            {/*  <a href="javasccript:void(0);"><img src="/icon_01.png" width="60" alt="Mazrui"/></a>*/}
            {/*  <a href="javasccript:void(0);"><img src="/icon_02.png" width="60" alt="Mazrui"/></a>*/}
            {/*  <a href="javasccript:void(0);"><img src="/icon_03.png" width="60" alt="Mazrui"/></a>*/}
            {/*</div>*/}
          </Col>
        </div>
      </div>
    </div>
  )
}

export default LoginSection
