import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../components/seo"
import LoginSection from "../components/partials/login"

const Login = ({ location, state }) => {
  const {
    site: {
      siteMetadata: { apiURL },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            apiURL
          }
        }
      }
    `,
  )

  const onLoginSuccess = ({ access_token, user }) => {
    const { handleLogin } = state
    handleLogin({
      token: access_token,
      user,
      isLoggedIn: true,
    })
  }

  return (
    <div className="container py-md-5" id="registration-form">
      <div className="row mt-4 justify-content-center">
        <LoginSection
          endpoint={
            process.env.GATSBY_API_LOGIN_ENDPOINT ||
            `${apiURL}/login` ||
            undefined
          }
          location={location}
          redirectURL={((location || {}).state || {}).redirect || "/"}
          onSucess={onLoginSuccess}
        />
        <SEO title="Login" keywords={[`mazrui`, `careers`]}/>
      </div>
    </div>
  )
}

export default Login
